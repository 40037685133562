const data = {
  data: `<h1 align="center" style="font-size:1.5rem;margin-top:0in;margin-right:1.0pt;margin-bottom:20px;
margin-left:0in;text-indent:0in;line-height:107%"><span lang="PT-BR" style="color:windowtext">Termo de Consentimento para Tratamento de
Dados </span></h1>

    <p class="MsoNormal" align="left" style="margin:0in;text-align:left;text-indent:
0in;line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:.5pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">Este documento visa
registrar a manifestação livre, informada e inequívoca pela qual o Titular
concorda com o tratamento de seus dados pessoais para finalidade específica, em
conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD).
</span></p>

    <p class="MsoNormal" align="left" style="margin:0in;text-align:left;text-indent:
0in;line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:.55pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">Ao manifestar sua
aceitação para com o presente termo, o Titular consente e concorda que a Unindo
Sonhos (@Unindosonhoss) – PC de S LEITE – LTDA, inscrição no CNPJ sob o nº
16.742.811/0001-06, com sede na </span><span lang="PT-BR">Av. Nossa Senhora da
Consolata, 645 c – Centro – Boa Vista/Roraima – CEP: 69301 - 011</span><span lang="PT-BR" style="color:windowtext">, doravante denominada Controladora, tome
decisões referentes ao tratamento de seus dados pessoais, dados referentes as
empresas em que atuem os usuários ou dados necessários ao usufruto de serviços
ofertados, bem como realize o tratamento de tais dados, envolvendo operações
como as que se referem a coleta, produção, recepção, classificação, utilização,
acesso, reprodução, transmissão, distribuição, processamento, arquivamento,
armazenamento, eliminação, avaliação ou controle da informação, modificação,
comunicação, transferência, difusão ou extração. </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:1.05pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:2.15pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.0pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">Dados Pessoais </span></p>

    <p class="MsoNormal" align="left" style="margin:0in;text-align:left;text-indent:
0in;line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.35pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">A Controladora fica
autorizada a tomar decisões referentes ao tratamento e a realizar o tratamento
dos seguintes dados pessoais do Titular: </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:1.95pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.3pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Nome completo; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.5pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Data de nascimento; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.35pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Número e imagem da
Carteira de Identidade (RG); </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.5pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Número e imagem do
Cadastro de Pessoas Físicas (CPF); </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.35pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Número e imagem da
Carteira Nacional de Habilitação (CNH); </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.25pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Fotografia 3x4; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.3pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Estado civil; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.35pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Filiação; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.4pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Nível de instrução ou
escolaridade; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.4pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Endereço completo; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.7pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Números de telefone,
WhatsApp, endereços de e-mail e identificações em redes sociais; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.5pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Banco, agência e número
de contas bancárias; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.4pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Bandeira, número,
validade e código de cartões de crédito; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.5pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Nome de usuário e senha
específicos para uso dos serviços do Controlador; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.9pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Extratos de
movimentações efetuadas pelo Titular em sua conta no </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:.6pt;
margin-left:35.8pt"><span lang="PT-BR" style="color:windowtext">Portal/Aplicativo
@Unindosonhoss. </span></p>

    <p class="MsoNormal" align="left" style="margin:0in;text-align:left;text-indent:
0in;line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:.55pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">Além disso, a
Controladora fica autorizada a tomar decisões referentes ao tratamento e a
realizar o tratamento dos dados inseridos pelo Titular, com a finalidade de
divulgação dos serviços, inclusive publicidade. </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:2.1pt;margin-left:0in;text-align:left;text-indent:0in;line-height:
107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.0pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">Finalidades do
Tratamento dos Dados </span></p>

    <p class="MsoNormal" align="left" style="margin:0in;text-align:left;text-indent:
0in;line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.1pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">O tratamento dos
dados pessoais listados neste termo tem as seguintes finalidades: </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:2.1pt;margin-left:0in;text-align:left;text-indent:0in;line-height:
107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.85pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Possibilitar que a
Controladora identifique e entre em contato com o Titular para fins de
relacionamento comercial. </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.7pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Possibilitar que a
Controladora elabore contratos comerciais e emita cobranças contra o Titular. </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.85pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Possibilitar que a
Controladora envie ou forneça ao Titular seus produtos e serviços, de forma
remunerada ou gratuita. </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.7pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Possibilitar que a
Controladora estruture, teste, promova e faça propaganda de produtos e
serviços, personalizados ou não ao perfil do Titular. </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.5pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Possibilitar que a
Controladora utilize tais dados em Pesquisas de Mercado; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.7pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Possibilitar que a
Controladora utilize tais dados na inscrição, divulgação, premiação dos
interessados participantes de Eventos, Prêmios ou Concursos; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.85pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Possibilitar que a
Controladora utilize tais dados na elaboração de relatórios e emissão de
produtos e serviços; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.65pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Possibilitar que a
Controladora utilize tais dados para emissão de Notas Fiscais e documentos
financeiros correlatos; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.85pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Possibilitar que a
Controladora utilize tais dados para facilitar a prestação de serviços diversos
além dos primariamente contratados, desde que o cliente também demonstre
interesse em contratar novos serviços; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.7pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Possibilitar que a
Controladora utilize tais dados para manter banco de dados de profissionais do
mercado para facilitar o contato em futuros convites para eventos; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.9pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Possibilitar que a
Controladora utilize tais dados para: (i) mitigar fraudes eletrônicas
considerando a análise de perfil do Titular, histórico de transações realizadas
e informações existentes em bancos de dados e aplicando o modelo estatístico de
indicador de propensão à fraude pelas mesas de análise de risco, a fim de
conferir o status sobre a suspeita de fraude eletrônica e evitar que sejam
realizadas transações por terceiros em nome do Titular e/ou utilizando
informações falsas; (ii) criar um cadastro de pontuação baseada no histórico de
transações realizadas pelo Titular; e (iii) fins estatísticos sem a
individualização dos referidos dados. </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.45pt;
margin-left:34.55pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">Exercício do
contraditório e ampla defesa. </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:2.05pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.0pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">Compartilhamento de
Dados </span></p>

    <p class="MsoNormal" align="left" style="margin:0in;text-align:left;text-indent:
0in;line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:.5pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">A Controladora fica
autorizada a compartilhar os dados pessoais do Titular com outros agentes de
tratamento de dados, caso seja necessário para as finalidades listadas neste
termo, observados os princípios e as garantias estabelecidas pela Lei nº
13.709/18. </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:2.05pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.1pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">Segurança dos
Dados </span></p>

    <p class="MsoNormal" align="left" style="margin:0in;text-align:left;text-indent:
0in;line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:.55pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">A Controladora
responsabiliza-se pela manutenção de medidas de segurança, técnicas e
administrativas aptas a proteger os dados pessoais de acessos não autorizados e
de situações acidentais ou ilícitas ou qualquer forma de tratamento inadequado
ou ilícito. </span></p>

    <p class="MsoNormal" align="left" style="margin:0in;text-align:left;text-indent:
0in;line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:.55pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">Em conformidade ao
art. 48 da Lei nº 13.709/18, a Controladora comunicará ao Titular e à
Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de
segurança que possa acarretar risco ou dano relevante ao Titular. </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:2.1pt;margin-left:0in;text-align:left;text-indent:0in;line-height:
107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.0pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">Término do Tratamento
dos Dados </span></p>

    <p class="MsoNormal" align="left" style="margin:0in;text-align:left;text-indent:
0in;line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:.6pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">A Controladora
poderá manter e tratar os dados pessoais do Titular durante todo o período em
que os mesmos forem pertinentes ao alcance das finalidades listadas neste
termo. Dados pessoais anonimizados, sem possibilidade de associação ao
indivíduo, poderão ser mantidos por período indefinido. </span></p>

    <p class="MsoNormal" align="left" style="margin:0in;text-align:left;text-indent:
0in;line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:.5pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">O Titular poderá
solicitar via e-mail ou correspondência ao Controlador, a qualquer momento, que
sejam eliminados os dados pessoais não anonimizados do Titular. O Titular fica
ciente de que poderá ser inviável ao Controlador continuar o fornecimento de produtos
ou serviços ao Titular a partir da eliminação dos dados pessoais. </span></p>

    <p class="MsoNormal" align="left" style="margin:0in;text-align:left;text-indent:
0in;line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:.5pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">Em especial o
Titular de dados autoriza, nos termos do art. 7º VI da LGPD, que a Unindo
Sonhos (@Unindosonhoss) conserve seus dados pelo prazo de 10 (dez) anos para
fins de eventual exercício de direitos fundamentais, como o exercício de
Contraditório e Ampla Defesa em eventual processo judicial e/ou
administrativo. </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:1.05pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:2.05pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.1pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">Direitos do
Titular </span></p>

    <p class="MsoNormal" align="left" style="margin:0in;text-align:left;text-indent:
0in;line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.35pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">O Titular tem
direito a obter da Controladora, em relação aos dados por ele tratados, a
qualquer momento e mediante requisição: </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:2.05pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.3pt;
margin-left:37.9pt;text-indent:-21.35pt"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">confirmação da
existência de tratamento; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.5pt;
margin-left:37.9pt;text-indent:-21.35pt"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">acesso aos dados; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:2.4pt;
margin-left:37.9pt;text-indent:-21.35pt"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">correção de dados
incompletos, inexatos ou desatualizados; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.85pt;
margin-left:37.9pt;text-indent:-21.35pt"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">anonimização, bloqueio
ou eliminação de dados desnecessários, excessivos ou tratados em
desconformidade com o disposto na Lei nº 13.709/18; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.85pt;
margin-left:37.9pt;text-indent:-21.35pt"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">portabilidade dos dados
a outro fornecedor de serviço ou produto, mediante requisição expressa e
observados os segredos comercial e industrial, de acordo com a regulamentação
do órgão controlador; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.85pt;
margin-left:37.9pt;text-indent:-21.35pt"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">portabilidade dos dados
a outro fornecedor de serviço ou produto, mediante requisição expressa, de
acordo com a regulamentação da autoridade nacional, observados os segredos
comercial e industrial; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.7pt;
margin-left:37.9pt;text-indent:-21.35pt"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">eliminação dos dados
pessoais tratados com o consentimento do titular, exceto nas hipóteses
previstas no art. 16 da Lei nº 13.709/18; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.7pt;
margin-left:37.9pt;text-indent:-21.35pt"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">informação das
entidades públicas e privadas com as quais a Controladora realizou uso
compartilhado de dados; </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.45pt;
margin-left:37.9pt;text-indent:-21.35pt"><span lang="PT-BR" style="line-height:
107%">•<span style="font:7.0pt &quot;Times New Roman&quot;">
</span></span><span lang="PT-BR" style="color:windowtext">informação sobre a
possibilidade de não fornecer consentimento e sobre as consequências da
negativa; </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:2.1pt;margin-left:0in;text-align:left;text-indent:0in;line-height:
107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.1pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">Direito de
Revogação do Consentimento </span></p>

    <p class="MsoNormal" align="left" style="margin:0in;text-align:left;text-indent:
0in;line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.35pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">Este consentimento
poderá ser revogado pelo Titular, a qualquer momento, mediante solicitação via
e-mail ou correspondência à Controladora. </span></p>`,
};
export default data;
